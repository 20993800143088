import sqlite3InitModule, {Database, SqlValue } from '@sqlite.org/sqlite-wasm';
import React, {useEffect, useRef, useState} from "react";
import {EditRoute, Route, RouteStation, Station, StopTime, Train, TrainType, Trip} from "../DiaData/DiaData";
import {getStationViewWidth, StationView} from './StationView';
import {getTripNameViewHeight, TripNameView} from './TripNameView';
import {TripView} from "./TripVIew";

async function fetchGzipJson(url: string): Promise<any> {
    const response = await fetch(url);
    //@ts-ignore
    const rstream = response.body.pipeThrough(new DecompressionStream('gzip'));
    // ReadableStream を Response に変換
    const response2 = new Response(rstream);
    // Response を JSON に変換
    const json = await response2.json();
    return json;
}

export interface TimeTablePageSetting{
    fontSize:number
}



export default function TimeTablePage() {
    const [db,setDb] = useState<Database|undefined>(undefined);
    const [stations, setStations] = useState<{[key:number]:Station}>([]);
    const [trainTypes, setTrainTypes] = useState<{[key:number]:TrainType}>([]);
    const [trains, setTrains] = useState<{[key:number]:Train}>([]);
    const [routes, setRoutes] = useState<{[key:number]:Route}>([]);
    const routeID=1439811124025084;
    const [setting,setSetting]=useState<TimeTablePageSetting>({
        fontSize:24
    });



    function download(){
        const a = document.createElement('a');
        const blob = new Blob([JSON.stringify(routes[routeID])], { "type" : "application/json" });

        a.href = URL.createObjectURL(blob);
        a.download = 'data.json';
        a.click();


        const blob2 = new Blob([JSON.stringify(trains)], { "type" : "application/json" });
        a.href = URL.createObjectURL(blob2);
        a.download = 'data2.json';
        a.click();

    }

    const [time,setTime]=useState<number>(0);

   useEffect(() => {
       setTime(Date.now());
      initDb();
   }, []);
    useEffect(() => {
        console.log("endLoad:"+(Date.now()-time));
        console.log(routes[routeID]);
    }, [routes]);

   useEffect(() => {
       console.log("startLoad:"+(Date.now()-time));
        if(db===undefined)return;

        let resultRows: any[] = [];
        db.exec({
            sql: "select * from station",
            rowMode: "object",
            resultRows: resultRows,
        });
        const stations:{[key:number]:Station}={};
        resultRows.forEach((item)=>{
            stations[item.station_id]= {
                stationID:item.station_id,
                name:item.station_name,
                lat:item.lat,
                lon:item.lon
            }});

       console.log("startLoad1:"+(Date.now()-time));

        resultRows=[];
        db.exec({
            sql: "select * from type",
            rowMode: "object",
            resultRows: resultRows,
        });

       const trainType:{[key:number]:TrainType}={};
        resultRows.forEach((item)=>{
            trainType[item.type_id]=
                {
                    trainTypeID:item.type_id,
                    name:item.name,
                    shortName:item.short_name,
                    color:item.color,
                    bold:item.bold===1,
                    dot:item.dot===1
                }
        });
       console.log("startLoad2:"+(Date.now()-time));

       // resultRows=[];
       //  db.exec({
       //      sql: "select * from trains",
       //      rowMode: "object",
       //      resultRows: resultRows,
       //  });
       // console.log("startLoad2:"+(Date.now()-time));
       //
       // const trains:{[key:number]:Train}={};
       //  resultRows.forEach((item)=>{
       //      trains[item.train_id]=
       //          {
       //              trainID:item.train_id,
       //              name:item.name,
       //              remark:item.remark
       //          }
       //  });
       fetchGzipJson(`trains.json.gz`).then(res=>{
           setTrains(res);
       });
       console.log("startLoad3:"+(Date.now()-time));

       setStations(stations);
        setTrainTypes(trainType);
       console.log("startLoad4:"+(Date.now()-time));

       loadRoute().then((route)=>{
           EditRoute.sortTrips(route,0,0);
           setRoutes((prev)=>{
               const next  = {...prev};
               if(route!==undefined){
                   next[route.routeID]=route;
               }
               return next;
           })
       });

    }, [db]);


    async function  loadRoute():Promise<Route>{
//        return await fetchGzipJson(`route_${routeID}.json.gz`) as Route;

        if(db===undefined){
            return {
                routeID:0,
                name:"",
                routeStation:[],
                downTrips:[],
                upTrips:[]
            }
        }
        let resultRows: any[] = [];
        db.exec({
            sql: `select * from route where route_id=:routeID`,
            rowMode: "object",
            resultRows: resultRows,
            bind: {":routeID": routeID}
        });

        if(resultRows.length===0){
            return {
                routeID:0,
                name:"",
                routeStation:[],
                downTrips:[],
                upTrips:[]
            }

        };
        const route:Route={
            routeID:routeID,
            name:resultRows[0].name,
            routeStation:[],
            downTrips:[],
            upTrips:[]
        };
        resultRows=[];
        db.exec({
            sql:`select * from route_station where route_id=:routeID order by station_index`,
            rowMode:"object",
            resultRows:resultRows,
            bind:{":routeID":routeID}
        })
        route.routeStation=resultRows.map((item):RouteStation=>{
        return{
                rsID:item.route_station_id,
                routeID:item.route_id,
                stationIndex:item.station_index,
                stationID:item.station_id,
                showStyle:item.showStyle,
                main:item.main===1
            }
        });


        resultRows=[];
        db.exec({
            sql:`select * from trip where route_id=:routeID and direction=0`,
            rowMode:"object",
            resultRows:resultRows,
            bind:{":routeID":routeID}
        })
        route.downTrips=resultRows.map((item):Trip=>{
            const res:any[]=[];
            db.exec({
                sql:`select * from time left join route_station on time.route_station_id = route_station.route_station_id where trip_id = :tripID order by route_station.station_index;`,
                rowMode:"object",
                resultRows:res,
                bind:{":tripID":item.trip_id}
            })
            return{
                tripID:item.trip_id,
                routeID:item.route_id,
                direction:item.direction,
                trainID:item.train_id,
                trainTypeID:item.type_id,
                times:res.map((item):StopTime=>{
                    return{
                        tripID:item.trip_id,
                        rsID:item.route_station_id,
                        stopType:item.stop_type,
                        ariTime:item.ari_time,
                        depTime:item.dep_time
                    }
                })
            }
        });
        resultRows=[];
        db.exec({
            sql:`select * from trip where route_id=:routeID and direction=1`,
            rowMode:"object",
            resultRows:resultRows,
            bind:{":routeID":routeID}
        })

        route.upTrips=resultRows.map((item):Trip=>{
            const res:any[]=[];

            db.exec({
                sql:`select * from time  left join route_station on time.route_station_id = route_station.route_station_id where trip_id = :tripID order by route_station.station_index;`,
                rowMode:"object",
                resultRows:res,
                bind:{":tripID":item.trip_id}
            })
            return{
                tripID:item.trip_id,
                routeID:item.route_id,
                direction:item.direction,
                trainID:item.train_id,
                trainTypeID:item.type_id,
                times:res.map((item):StopTime=>{
                    return{
                        tripID:item.trip_id,
                        rsID:item.route_station_id,
                        stopType:item.stop_type,
                        ariTime:item.ari_time,
                        depTime:item.dep_time
                    }
                })
            }
        });


        return route;
    }

   const initDb = async () => {
      try {
         const sqlite3 = await sqlite3InitModule();
         const buf=await(await fetch("/diadata.db")).arrayBuffer();
         const u8array = new Uint8Array(buf);
         const db = new sqlite3.oo1.DB();
         const rc = sqlite3.capi.sqlite3_deserialize(
             // @ts-ignore
             db.pointer, 'main', sqlite3.wasm.allocFromTypedArray(u8array), u8array.byteLength, u8array.byteLength,
             sqlite3.capi.SQLITE_DESERIALIZE_FREEONCLOSE
         );
         db.checkRc(rc);
         setDb(db);





      }
      catch (err) {
         console.error(err);
      }
   };
    useEffect(() => {
        window.onscroll=(e=>{
            const tripNameLayout=document.getElementById("tripNameLayout")
            const stationViewLayout=document.getElementById("stationViewLayout")
            if(tripNameLayout!==null){
                tripNameLayout.style.left=-window.scrollX+"px";
            }
            if(stationViewLayout!==null){
                stationViewLayout.style.top=-window.scrollY+"px";
            }

        })

    }, []);
    function getStationProps(){
        return routes[routeID].routeStation.map((item)=>{
            return {
                rsID:item.rsID,
                name:stations[item.stationID].name,
                style:item.showStyle
            }
        });
    }

    if(routes[routeID]===undefined){
        return <div>loading</div>
    }


    return (
        <div style={{display:"flex",width:'100%',height:'100%'}} >
            <div style={{width:`${getStationViewWidth(setting)}px`,borderRight:"2px solid black",borderBottom:"2px solid black",position: "fixed",height:`${getTripNameViewHeight(setting)}px`,background:"white",zIndex:"10"}}></div>
            <div style={{width:`${getStationViewWidth(setting)}px`,borderRight:"2px solid black", position:'fixed',paddingTop:`${getTripNameViewHeight(setting)}px`,background:"white"}} id="stationViewLayout">
                <StationView stations={getStationProps()} setting={setting}/>
            </div>
            <div style={{flexShrink: 1, flexGrow: 1}}>
                <div id="tripNameLayout" style={{borderBottom:"2px solid black",display:'flex',background:"white",position: "fixed",  paddingLeft: `${getStationViewWidth(setting)}px`,height: `${getTripNameViewHeight(setting)}px`}}>
                        {
                            routes[routeID].downTrips.map((trip) =>
                                <TripNameView key={trip.tripID} trip={trip} type={trainTypes[trip.trainTypeID]} setting={setting}/>
                            )}
                </div>
                <div>
                    <div style={{display: "flex",borderBottom:"2px solid black", paddingLeft: `${getStationViewWidth(setting)}px`,paddingTop:`${getTripNameViewHeight(setting)}px`}} id="tripTimeLayout" >
                        {
                            routes[routeID].downTrips.map((trip) =>
                                <TripView key={trip.tripID} trip={trip} type={trainTypes[trip.trainTypeID]} setting={setting} stations={getStationProps()}/>
                            )}
                    </div>
                </div>
            </div>


        </div>
    );

}